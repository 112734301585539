import React, { useContext } from 'react';
import { styled } from '@compiled/react';
import memoize from 'memoize-one';
import { useIntl } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';

import { token } from '@atlaskit/tokens';
import { B50, N40, N70 } from '@atlaskit/theme/colors';

import { RoutesContext } from '@confluence/route-manager';
import { usePageContentId } from '@confluence/page-context';
import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { PageSegmentLoadEnd, PageSegmentLoadStart } from '@confluence/browser-metrics';
import { FocusedInlineCommentQuery } from '@confluence/inline-comments-queries/entry-points/preloadFocusedInlineComment';
import { i18n } from '@confluence/inline-comments-common/entry-points/i18n';
import {
	SidebarContainer,
	NewReplyContainer,
	ReplyListContainer,
	EditorContainer,
} from '@confluence/inline-comments-common/entry-points/styled';
import { getCommentIndexAndCount } from '@confluence/comments-util/entry-points/domUtils';

import { FOCUSED_INLINE_COMMENT_METRIC } from '../perf.config';

import { CommentAuthor } from './CommentAuthor';
import { CommentHeader } from './CommentHeader';
import { CommentActions } from './CommentActions';
import { CommentRenderer } from './CommentRenderer';

// Parse content to JSON
const parseContent = memoize((content) => {
	if (!content?.length) return null;
	try {
		return JSON.parse(content);
	} catch (error) {
		return null;
	}
});

type CommentContainerProps = {
	isFocused?: boolean;
};

type FocusedInlineCommentContainerProps = {
	showSkeleton: boolean;
	offset: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FocusedInlineCommentContainer = styled(
	SidebarContainer,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
)<FocusedInlineCommentContainerProps>(`
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    top: ${(props: FocusedInlineCommentContainerProps) => props.offset}px;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    display: ${(props: FocusedInlineCommentContainerProps) =>
			props.showSkeleton ? 'block' : 'none'};
    *:not(div#ssr-comment-renderer *) {
      color: ${token('color.text.subtlest', N70)} !important;
      cursor: not-allowed;
    }
  `);

/* Comment Editor CSS*/
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const EditorContainerSSR = styled(EditorContainer)(`
  > input {
    height: 30px;
    padding: ${token('space.050', '4px')};
    border: 1px solid ${token('color.border', N40)};
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 14px;
    width: 100%;
    font-weight: 400;
    line-height: 1.42;
  }
`);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const CommentContainer = styled.div<CommentContainerProps>(`
  display: flex;
  flex-direction: column;
  padding: ${token('space.150', '12px')} ${token('space.200', '16px')};
  border-left: 0;
  border-top: 0;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  background: ${(props: CommentContainerProps) =>
		props.isFocused ? token('color.background.brand.bold', B50) : undefined};
  blockquote {
    color: ${token('color.text', '#707070')};
  }
  outline: none;

  &:last-of-type {
    border-bottom: 0;
  }`);

export const getOffset = () => {
	if (
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']
	) {
		return window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['markerRefOffset'];
	}

	return 0;
};
// Makes sure to only show focusedInlineCommentSSR if the page was ssr'd
export const checkForSSRFocusedComment = () => {
	if (
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']
	) {
		return true;
	}

	return false;
};

export const FocusedInlineComment = () => {
	const { formatMessage } = useIntl();
	const [contentId] = usePageContentId();

	const { match } = useContext(RoutesContext);

	const focusedCommentId = match?.query.focusedCommentId;

	const { data } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		FocusedInlineCommentQuery,
		{
			variables: { commentId: focusedCommentId, contentId },
			skip: !(contentId && focusedCommentId),
		},
	);

	let commentData;

	let isTopLevelComment = true;

	/* If focusedCommentId is a reply grab top-level comment Data from ancestors.
  If ancestors is non-empty it means focusedCommentId is a reply*/
	if (data?.comments?.nodes[0]?.ancestors.length > 0) {
		commentData = data?.comments?.nodes[0]?.ancestors?.[0];
		isTopLevelComment = false;
	} else {
		commentData = data?.comments?.nodes[0];
	}

	if (
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']
	) {
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['isTopLevelComment'] =
			isTopLevelComment;
	}

	const content = data?.content?.nodes?.[0];
	const operations = content?.operations || [];

	const canCreateComments = Boolean(
		operations.find((op) => op?.operation === 'create' && op?.targetType === 'comment'),
	);

	const topCommentUserId = commentData?.author?.accountId;
	const topCommentUserAvatar = commentData?.author?.profilePicture?.path;
	const topCommentDisplayName = commentData?.author?.displayName;
	const commentId = commentData?.id;
	const when = commentData?.version?.when;
	const commentContent = commentData?.body?.value;
	const permissionType = commentData?.author?.permissionType;

	const currentUserAvatarUrl = data?.user?.photos[0].value || null;
	const currentUserDisplayName = data?.user?.displayName || null;
	const currentUserId = data?.user?.id;

	const adf = parseContent(commentContent);

	const renderReplies = () => {
		const replies = commentData?.replies || [];

		return replies.map((reply) => {
			const adf = parseContent(reply?.body?.value);
			return (
				<CommentContainer
					key={reply?.id}
					isFocused={focusedCommentId === reply?.id}
					data-comment-id={reply?.id}
				>
					<CommentAuthor
						commentMode="view"
						userId={reply?.author?.accountId}
						avatarUrl={reply?.author?.profilePicture?.path}
						displayName={reply?.author?.displayName}
						date={reply?.version?.when}
						permissionType={reply?.author?.permissionType}
					/>
					{adf && <CommentRenderer adf={adf} />}
					<CommentActions isReply permissions={reply?.permissions} />
				</CommentContainer>
			);
		});
	};

	// Scroll to focusedInlineComment if it's out of the screen
	const scrollOffset =
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment'] &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['scrollOffset'];

	if (scrollOffset) {
		window.scrollTo({
			top: scrollOffset,
		});
	}

	const { commentIndex, totalComments } = getCommentIndexAndCount();

	if (data) {
		return (
			<TransparentErrorBoundary attribution={Attribution.COLLABORATION}>
				{process.env.REACT_SSR && (
					<PageSegmentLoadStart key={`start-${contentId}`} metric={FOCUSED_INLINE_COMMENT_METRIC} />
				)}
				<FocusedInlineCommentContainer
					mode="view"
					id="focused-inline-comment-sidebar-skeleton"
					data-testid="focused-inline-comment-sidebar-skeleton"
					showSkeleton={checkForSSRFocusedComment()}
					offset={getOffset()}
				>
					<CommentHeader
						commentIndex={commentIndex as number}
						totalComments={totalComments as number}
					/>
					{/* eslint-disable-next-line jsx-a11y/aria-role */}
					<CommentContainer role="comment" tabIndex={-1} data-comment-id={commentId}>
						<CommentAuthor
							commentMode="view"
							userId={topCommentUserId}
							avatarUrl={topCommentUserAvatar}
							displayName={topCommentDisplayName}
							date={when}
							permissionType={permissionType}
							size="medium"
						/>
						{adf && <CommentRenderer adf={adf} />}
						<CommentActions permissions={commentData?.permissions} isReply={false} />
					</CommentContainer>
					<ReplyListContainer data-testid="reply-container">{renderReplies()}</ReplyListContainer>
					{canCreateComments && (
						<NewReplyContainer mode="view">
							<EditorContainerSSR isReply mode="view">
								<CommentAuthor
									commentMode="reply"
									userId={currentUserId as string}
									avatarUrl={currentUserAvatarUrl}
									displayName={currentUserDisplayName}
									date={when}
									permissionType={permissionType}
									size="small"
								/>
								<input disabled placeholder={formatMessage(i18n.replyPlaceholder)} />
							</EditorContainerSSR>
						</NewReplyContainer>
					)}
				</FocusedInlineCommentContainer>
				{process.env.REACT_SSR && (
					<PageSegmentLoadEnd key={`stop-${contentId}`} metric={FOCUSED_INLINE_COMMENT_METRIC} />
				)}
			</TransparentErrorBoundary>
		);
	}

	return null;
};
